<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <main v-if="user && user.data.position.toLowerCase() !== 'vendor'">
        <LoadingSpinner v-if="loading" />
        <section v-else class="flex justify-between mx-32 my-20">
            <dashboard-card class="bg-cardBackgroundColor-pink">
                <template v-slot:items-number>
                    <div>{{ totalVendorsCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">Total vendors</div>
                </template>
            </dashboard-card>
            <dashboard-card class="bg-cardBackgroundColor-purple">
                <template v-slot:items-number>
                    <div>{{ totalRFQCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">Total Requests for quote</div>
                </template>
            </dashboard-card>
            <dashboard-card class="bg-cardBackgroundColor-green">
                <template v-slot:items-number>
                    <div>{{ totalExpenditureRequestsCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">Total Expenditure requests</div>
                </template>
            </dashboard-card>
        </section>
    </main>
    <main v-if="user && user.data.position.toLowerCase() === 'vendor'">
        <LoadingSpinner v-if="vendorQuotesCount === null" />
        <section v-else class="flex justify-between mx-32 my-20">
            <dashboard-card class="bg-cardBackgroundColor-pink">
                <template v-slot:items-number>
                    <div>{{ vendorQuotesCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">Total Request for quote</div>
                </template>
            </dashboard-card>
            <dashboard-card class="bg-cardBackgroundColor-purple">
                <template v-slot:items-number>
                    <div>{{ vendorApprovedBidsCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">Approved bids</div>
                </template>
            </dashboard-card>
            <dashboard-card class="bg-cardBackgroundColor-green">
                <template v-slot:items-number>
                    <div>{{ vendorRejectedBidsCount }}</div>
                </template>
                <template v-slot:item-label>
                    <div class="tracking-widest uppercase text-sm">rejected bids</div>
                </template>
            </dashboard-card>
        </section>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import DashboardCard from '@/components/ui/DashboardCard.vue'
import { mapState } from 'vuex'
import errorFunc from '@/util/error'

export default {
    components: { DashboardCard },
    computed: {
        ...mapState(['user']),
        currentUser() {
            return this.user.data.position.toLowerCase()
        },
    },
    mounted() {
        if (this.currentUser !== 'vendor') {
            this.getVendorsCount()
            this.getRFQCount()
            this.getExpenditureRequestCount()
        }

        if (this.currentUser === 'vendor') {
            this.getVendorQuotesCount()
            this.getVendorsRejectedQuotesCount()
            this.getVendorsApprovedCount()
        }
    },
    data() {
        return {
            totalVendorsCount: null,
            totalRFQCount: null,
            totalExpenditureRequestsCount: null,
            errorMessage: null,
            vendorQuotesCount: null,
            vendorApprovedBidsCount: null,
            vendorRejectedBidsCount: null,
            loading: false,
        }
    },
    methods: {
        async getVendorsCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest('vendors/count')

                if (status === 200) {
                    this.loading = false
                    this.totalVendorsCount = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getRFQCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest('request/quote')
                if (status === 200) {
                    this.loading = false
                    this.totalRFQCount = data.data.length
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getExpenditureRequestCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest(
                    'expenditure/requests/count',
                    process.env.VUE_APP_FINANCE_BASE_URL
                )
                if (status === 200) {
                    this.loading = false
                    this.totalExpenditureRequestsCount = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getVendorQuotesCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest(
                    `request/quote/vendors/count/${this.user.data.id}`
                )
                if (status === 200) {
                    this.loading = false
                    this.vendorQuotesCount = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getVendorsApprovedCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest(
                    `vendors/approved/quotation/count/${this.user.data.id}`
                )

                if (status === 200) {
                    this.loading = false
                    this.vendorApprovedBidsCount = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getVendorsRejectedQuotesCount() {
            try {
                this.loading = true
                const { status, data } = await Request.getRequest(
                    `vendors/rejected/quotation/count/${this.user.data.id}`
                )

                if (status === 200) {
                    this.loading = false
                    this.vendorRejectedBidsCount = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
