import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/views/Dashboard.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "createBudget" */ '@/views/Login.vue'),
    },
    {
        path: '/quotes',
        name: 'quotes',
        component: () => import(/* webpackChunkName: "quotesHome" */ '@/views/QuotesHome.vue'),
    },
    {
        path: '/quotes/new',
        name: 'newQuote',
        component: () => import(/* webpackChunkName: "newQuotes" */ '@/views/NewQuotes.vue'),
    },
    {
        path: '/quotes/:quoteId',
        name: 'quoteView',
        component: () => import(/* webpackChunkName: "quoteView" */ '@/views/QuoteView.vue'),
        props: true
    },
    {
        path: '/quotes/:quoteId/bid',
        name: 'vendorBid',
        component: () => import(/* webpackChunkName: "vendorBid" */ '@/views/VendorBid.vue'),
        props: true
    },
    {
        path: '/expenditure',
        name: 'expenditure',
        component: () => import(/* webpackChunkName: "expenditureHome" */ '@/views/ExpenditureHome.vue'),
    },
    {
        path: '/expenditure/all',
        name: 'allExpenditures',
        component: () => import(/* webpackChunkName: "allExpenditures" */ '@/views/ExpenditureHome.vue'),
    },
    {
        path: '/expenditure/department/:department',
        name: 'ExpenditureByDepartment',
        component: () => import(/* webpackChunkName: "allExpenditures" */ '@/views/ExpenditureHome.vue'),
    },
    {
        path: '/expenditure/:id',
        name: 'ExpenditureView',
        component: () => import(/* webpackChunkName: "expenditureView" */ '@/views/ExpenditureView.vue'),
        props: true
    },
    {
        path: '/expenditure/new',
        name: 'newExpenditure',
        component: () => import(/* webpackChunkName: "newExpenditure" */ '@/views/NewExpenditure.vue'),
    },
    {
        path: '/vendors',
        name: 'vendors',
        component: () => import(/* webpackChunkName: "vendorsHome" */ '@/views/VendorsHome.vue'),
    },
    {
        path: '/vendors/:vendorId',
        name: 'vendorView',
        component: () => import(/* webpackChunkName: "vendorView" */ '@/views/VendorView.vue'),
        props: true
    },
    {
        path: '/vendors/quotes/:quoteId',
        name: 'vendorQuoteView',
        component: () => import(/* webpackChunkName: "vendorQuoteView" */ '@/views/VendorQuoteView.vue'),
        props: true
    },
    {
        path: '/vendors/new',
        name: 'newVendor',
        component: () => import(/* webpackChunkName: "newVendor" */ '@/views/NewVendor.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
