<template>
  <main v-if="errorMessage" class="fixed top-44 right-6 w-80 h-14 border border-red-500 bg-errorToastBg rounded-md flex items-center justify-start z-50">
      <img src="@/assets/images/failure-toast-icon.svg" alt="login failed" class="w-12">
      <slot name="error-message"></slot>
  </main>
</template>

<script>
export default {
    props: ['errorMessage'],
    watch: {
        errorMessage() {
            if (this.errorMessage) {
                setTimeout(() => {
                    this.$emit('resetErroMessage')
                }, 4000)
            }
        }
    }
}
</script>
