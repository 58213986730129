<template>
    <div class="w-1/3 h-44 mr-12 text-white rounded-md flex flex-col items-center justify-center ">
        <div class="text-5xl font-extrabold text-center leading-10">
            <slot name="items-number" />
        </div>
        <div class="pt-4">
            <slot name="item-label" />
        </div>
    </div>
</template>
