<template>
  <main v-if="infoMessage" class="fixed top-44 right-6 w-80 h-14 border border-blue-500 bg-infoToastBg rounded-md flex items-center justify-start z-50">
      <img src="@/assets/images/info-toast-icon.svg" :alt="infoMessage" class="w-12">
      <slot name="info-message"></slot>
  </main>
</template>

<script>
export default {
    props: ['infoMessage'],
    watch: {
        infoMessage() {
            if (this.infoMessage) {
                setTimeout(() => {
                    this.$emit('resetInfoMessage')
                }, 4000)
            }
        }
    }
}
</script>
