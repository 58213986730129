<template>
  <button @click="$emit('submitRequest')" class="bg-primaryColor text-white text-center py-3 px-10 rounded shadow-md focus:outline-none">
      {{ buttonText }}
    </button>
</template>

<script>
export default {
    props: { buttonText: String }
}
</script>
